.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.page{
  margin-left: 180px;
}
.icon-button-edit{
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 8px;
  border: 1px solid gray;
  background-color: cornflowerblue;
  font-size: 12px;
}
.icon-button-delete{
  cursor: pointer;
  border: 1px solid gray;
  background-color:brown;
  color: #fff;
}
.icon-button-more{
  cursor: pointer;
  border: 1px solid gray;
  background-color:brown; 
  color: #fff;
  margin-right: 5px;
  width: 58px;
}

.icon-button-pay{
  cursor: pointer;
  border: 1px solid gray;
  background-color:green;
  color: #fff;
  margin-right: 5px;
  width: 82px;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
}
.content-register-page{
  display: flex;  
  text-align: center;
  margin: 0;
  width: 100%;
  margin-top: 10%;
  align-items: center;
  justify-content: center;
}